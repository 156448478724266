// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "../../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #6A2015;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/forms";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
/* ------- custom site button classes -------- */
$padding-y: .25rem;
$padding-x: .875rem;
$font-size: .875rem;
$border-radius: 1rem;

.btn-rounded {
    @include button-size($padding-y, $padding-x, $font-size, $border-radius);
}

$padding-y: 0rem;
$padding-x: .75rem;
$font-size: .75rem;
$border-radius: 1rem;

.btn-rounded-sm {
    @include button-size($padding-y, $padding-x, $font-size, $border-radius);
}